import React, { Component, Fragment } from "react";
import {
    Field,
    reduxForm,
    formValueSelector,
    change,
    FieldArray,
} from "redux-form";
import { connect } from "react-redux";
import {
    validate,
    validatorFromFunction,
    validators,
    combine,
} from "validate-redux-form";
import {
    renderField,
    renderFieldRadio,
    renderSelectField,
    SelectField,
    renderCurrency,
    renderSelectField2,
    renderFieldDPI,
} from "../../../Utils/renderField";
import { TipoTransaccion } from "../../../../../utility/constants";
import { getSimbolo } from "../../../Utils/renderField/renderTableField";

import { CreatableSelectField, SelectFieldNit } from "Utils/renderField/renderField";
import Modal from "react-responsive-modal";
import {
    RenderMoneda,
    RenderDateTime,
} from "../../../Utils/renderField/renderTableField";
import { replace, set } from "lodash";



const required = (value) => (value ? undefined : "Campo requerido.");
const validaNoEspacios = (value) => value && value.indexOf(" ") >= 0 ? "No se permiten espacios" : undefined;

class renderItemsNits extends Component {
    componentDidMount() {
        this.props.setCF();
    }
    render() {
        let {
            fields,
            simbolo,
            Nits,
            es_cf,
            tieneNits,
            validateNit,
            meta: { error, submitFailed },
            getFieldFormArray,
            setFieldFormArray,
            total,
            setDataName,
        } = this.props;
        let cont = 0;
        let tiene_cf = false;
        Nits.forEach((empresa) => {
            if ((empresa.Nit == "CF") | (empresa.Nit == "C/F")) {
                tiene_cf = true;
            }
            if (replace(empresa.Nit.toString(), "/", "").toUpperCase() == "CF") {
                tiene_cf = true;
            }
        });

        let optionsDocumentos = [
            { value: "NIT", label: "NIT", id: "NIT", tipo: "NIT" },
            { value: "CUI", label: "CUI", id: "CUI", tipo: "CUI" },
            { value: "EXT", label: "EXT", id: "EXT", tipo: "EXT" },
        ];

        const getOptions = (index) => {
            let options = [];
            let tipoField = getFieldFormArray(
                "PeticionVentaForm",
                "Ordennits",
                index,
                "tipo"
            );

            switch (tipoField) {
                case "NIT":
                    options = Nits.filter(
                        (nit) => nit.tipo && nit.tipo == "NIT"
                    ).filter((nit) => nit.Nit != "CF" && nit.Nit != "C/F");
                    if (total < 2500) {
                        // si no tiene un nit cf se le agrega
                        if (!tiene_cf && !tieneNits) {
                            options = [
                                ...options,
                                { Nit: "CF", nombre: "Consumidor Final", tipo: "NIT" },
                            ];
                        }
                        else {
                            let options_cf = Nits.filter(
                                (nit) => nit.Nit == "CF" | nit.Nit == "C/F"
                            );
                            options = [
                                ...options,
                                ...options_cf,
                            ];
                        }
                    }
                    else{
                        options = options.filter((nit) => nit.Nit != "CF" && nit.Nit != "C/F");
                    }
                    break;
                case "CUI":
                    options = Nits.filter(
                        (nit) => nit.tipo && nit.tipo == "CUI"
                    );
                    break;
                case "EXT":
                    options = Nits.filter(
                        (nit) => nit.tipo && nit.tipo == "EXT"
                    );
                    break;
                default:
                    options = [];
                    break;
            }
            return options;
        };

        const getOrNone = (index) => {
            let tipo = getFieldFormArray(
                "PeticionVentaForm",
                "Ordennits",
                index,
                "tipo"
            );
            if (tipo) {
                return tipo;
            }
            return "";
        };

        const getOrNoneNIT = (index) => {
            let propietario = getFieldFormArray(
                "PeticionVentaForm",
                "Ordennits",
                index,
                "propietario"
            );
            if (propietario) {
                return propietario;
            }
            return "";
        };

        const isReadOnly =  (index) => {
            let tipo = getOrNone(index);
            let Nit_ = getOrNoneNIT(index);
            let Nit = Nit_.Nit ? Nit_.Nit : "";
            if (tipo == "NIT") {
                if (Nit == "CF" || Nit == "C/F") {
                    return false;
                }
                if (Nit_.label == 'CF') return false;
            }
            if (tipo == "EXT") {
                return false;
            }
            if (tipo == "CUI") {
                return true;
            }
            return true;
        }

        return (
            <div>
                <table className="table table-striped table-highlight mb-0">
                    <thead>
                        <tr>
                            <th style={{ width: "14.5%" }}>
                                TIPO DE DOCUMENTO
                            </th>
                            <th style={{ width: "20.5%" }}>IDENTIFICACIÓN</th>
                            <th style={{ width: "5%" }}></th>
                            <th style={{ width: "30%" }}>NOMBRE</th>
                            <th style={{ width: "25%" }}>MONTO</th>
                        </tr>
                    </thead>
                    <tbody>
                        {fields.map((OrdenNit, index) => {
                            if (index == 0) {
                                return (
                                    <React.Fragment>
                                        <tr key={index}>
                                            <td className="sin-borde-top">
                                                <Field
                                                    name={`${OrdenNit}.tipo`}
                                                    type="text"
                                                    component={
                                                        renderSelectField2
                                                    }
                                                    validate={[required, validaNoEspacios]}
                                                    options={optionsDocumentos}
                                                    item_vacio={true}
                                                    disabled={false}
                                                    readOnly={true}
                                                    index={index}
                                                    onChange={(e) => {
                                                        setFieldFormArray(
                                                            "PeticionVentaForm",
                                                            "Ordennits",
                                                            index,
                                                            "nombre",
                                                            ""
                                                        );
                                                        setFieldFormArray(
                                                            "PeticionVentaForm",
                                                            "Ordennits",
                                                            index,
                                                            "propietario",
                                                            ""
                                                        );
                                                    }}
                                                />
                                            </td>

                                            <td className="sin-borde-top">
                                                <Field
                                                    name={`${OrdenNit}.propietario`}
                                                    type="text"
                                                    options={getOptions(index)}
                                                    component={this.props.tieneNits == true ? SelectFieldNit : CreatableSelectField}
                                                    labelKey="Nit"
                                                    valueKey="id"
                                                    placeholder="Seleccione o Esciba"
                                                    top={{
                                                        top: "67px",
                                                        position: "inherit",
                                                    }}
                                                    onChange={(e) => {
                                                        this.props.setNombre_nit(
                                                            index,
                                                            e.nombre
                                                        );
                                                        setTimeout(() => {
                                                            this.props.setCF(
                                                                false
                                                            );
                                                        }, 100);
                                                    }}
                                                />
                                            </td>
                                            <td
                                                className="text-center sin-borde-top"
                                                style={{ width: "48px" }}
                                            >
                                                <img
                                                    className="action-img boton-reactivo"
                                                    src={require("assets/img/icons/buscar.png")}
                                                    alt="Buscar"
                                                    title="Buscar"
                                                    onClick={() => {
                                                        let tipo_field = getOrNone(index);
                                                        if (tipo_field == "NIT") {
                                                            validateNit(index);
                                                        }
                                                        else if (tipo_field == "CUI") {
                                                            setDataName(index);
                                                        }
                                                    }}
                                                />
                                            </td>
                                            <td className="sin-borde-top">
                                                <Field
                                                    name={`${OrdenNit}.nombre`}
                                                    type="text"
                                                    component={renderField}
                                                    // readOnly={!es_cf}
                                                    // readOnly={[
                                                    //     "NIT",
                                                    //     "CUI",
                                                    //     "",
                                                    // ].includes(
                                                    //     getOrNone(index)
                                                    // )}
                                                    readOnly={isReadOnly(index)}
                                                    // top ={{top:"67px", position:"inherit"}}
                                                />
                                            </td>
                                            <td className="sin-borde-top">
                                                <Field
                                                    name={`${OrdenNit}.monto_factura`}
                                                    type="number"
                                                    prefix={simbolo}
                                                    component={renderCurrency}
                                                    top={{
                                                        top: "67px",
                                                        position: "inherit",
                                                    }}
                                                />
                                            </td>
                                        </tr>
                                    </React.Fragment>
                                );
                            } else {
                                return "";
                            }
                        })}
                    </tbody>
                </table>
                {/* <div className="d-flex justify-content-start mt-2">
                <button type="button" className="btn btn-primary py-0" onClick={() => fields.push({})}>
                    AGREGAR NIT
                </button>
            </div> */}
                {submitFailed && error && (
                    <div className="invalid-feedback-array text-danger">
                        {error}
                    </div>
                )}
            </div>
        );
    }
}

class PagoOCForm extends Component {
    state = {
        open_modal: false,
        es_pago: false,
        es_cuenta: true,
        es_gasto: false,
    };
    componentDidMount() {
        if (this.props.pago_inmediato == true) {
            this.setState({
                es_pago: false,
                open_modal: true,
            });
        }
    }
    // componentWillUpdate(pre)
    componentDidUpdate(prevProps, prevState) {
        if (this.props.pago_inmediato !== prevProps.pago_inmediato) {
            if (!this.props.pago_inmediato) {
                // this.setState({
                //     es_pago: false,
                //     open_modal: true
                // })
                this.props.setDias_de_credito(this.props.dias_credito);
            }
        }
    }
    renderDataFacturacion = () => {
        const {
            handleSubmit,
            previousStep,
            cuentas,
            tipo_documento,
            simbolo,
            es_cf,
            setCF,
            validateNit,
            getFieldFormArray,
        } = this.props;
        return (
            <Fragment>
                <div className="row col-12 m-0 p-0">
                    <div className={`form-group has-feedback  col-12 `}>
                        <FieldArray
                            name="Ordennits"
                            component={renderItemsNits}
                            setDataName={this.props.setDataName}
                            Nits={this.props.Nits}
                            simbolo={this.props.simbolo}
                            setNombre_nit={this.props.setNombre_nit}
                            es_cf={es_cf}
                            tieneNits={this.props.tieneNits}
                            setCF={setCF}
                            validateNit={validateNit}
                            getFieldFormArray={getFieldFormArray}
                            setFieldFormArray={this.props.setFieldFormArray}
                            total={this.props.total}
                        />
                    </div>
                </div>
            </Fragment>
        );
    };
    renderDiasCredito = () => {
        const { role } = this.props;

        return (
            <Fragment>
                <div className="row col-12 m-0 p-0">
                    <div
                        className={`form-group has-feedback  col-md-6  col-12 `}
                    >
                        <label className="t-azul" htmlFor="dias_al_credito">
                            Días de crédito
                        </label>
                        <Field
                            readOnly={role ? false : true}
                            name="dias_al_credito"
                            label="dias_al_credito"
                            component={renderField}
                            type="number"
                            className="form-control"
                        />
                    </div>
                </div>
            </Fragment>
        );
    };
    eliminarPago = (id, row) => {
        this.props.eliminarPagoCredito(id);
    };
    eliminarGasto = (id, row) => {
        this.props.eliminarGasto(id);
    };

    cerrarModal = () => {
        this.setState({ open_modal: false });
    };

    onSubmitTipoCuenta = (values) => {
        this.setState({
            es_cuenta: values.tipo_cuenta == "true" ? true : false,
        });
        this.cerrarModal();
    };
    render() {
        const {
            handleSubmit,
            previousStep,
            proveedor,
            agregarGastoOC,
            cuentas,
            agregarPagoCredito,
            simbolo,
            total,
            pago_inmediato,
        } = this.props;
        let total2 = Number(total).toFixed(2)
        
        return (
            
            <Fragment>
                <form
                    name="pagoOCForm"
                    className="form-validate mb-lg"
                    onSubmit={handleSubmit}
                >
                    <div className="row justify-content-center">
                        <h4 className="t-primary">Forma de Pago</h4>
                    </div>
                    <div className="col-12 m-0 p-0 d-flex justify-content-center">
                        <div className="col-md-10 row m-0 p-0">
                            <div className="row col-12 m-0 p-0 d-flex justify-content-start inline-header">
                                <div
                                    className={`form-group has-feedback col-md-4 col-sm-6 col-12`}
                                >
                                    <span
                                        className="t-primary h5 mr-3"
                                        htmlFor="descripcion"
                                    >
                                        Cliente:
                                    </span>
                                    <h3
                                        className="t-musgo"
                                        htmlFor="descripcion"
                                    >
                                        {proveedor}
                                    </h3>
                                </div>
                                <div
                                    className={`form-group has-feedback col-md-4 col-sm-6 col-12`}
                                >
                                    <span
                                        className="t-primary h5 mr-3"
                                        htmlFor="descripcion"
                                    >
                                        Total a pagar:
                                    </span>
                                    <h2
                                        className="t-mostaza"
                                        htmlFor="descripcion"
                                    >
                                        {
                                            <RenderMoneda
                                            
                                                monto={total2}
                                                simbolo={simbolo}
                                            />
                                        }
                                    </h2>
                                </div>
                                <div
                                    className={`form-group has-feedback col-md-4 col-sm-6 col-12`}
                                >
                                    <span
                                        className="t-primary h5 mr-3"
                                        htmlFor="descripcion"
                                    >
                                        {this.props.comparacion < 0
                                            ? "Crédito Excedido por"
                                            : "Crédito disponible:"}
                                    </span>
                                    <h2
                                        className={
                                            this.props.comparacion < 0
                                                ? "text-danger"
                                                : "t-primary"
                                        }
                                        htmlFor="descripcion"
                                    >
                                        {
                                            <RenderMoneda
                                                monto={Math.abs(
                                                    this.props.comparacion
                                                )}
                                                simbolo={simbolo}
                                            />
                                        }
                                    </h2>
                                </div>
                            </div>
                            <div className="row col-12 m-0 p-0 d-flex justify-content-center">
                                <div
                                    className={`form-group has-feedback  col-md-3 col-12`}
                                >
                                    <Field
                                        name="pago_inmediato"
                                        label="Pago Inmediato"
                                        component={renderFieldRadio}
                                        type="radio"
                                        value={"true"}
                                        className="form-control"
                                    />
                                </div>
                                <div
                                    className={`form-group has-feedback col-md-3  col-12`}
                                >
                                    <Field
                                        value={"false"}
                                        name="pago_inmediato"
                                        label="Pago al Crédito"
                                        component={renderFieldRadio}
                                        type="radio"
                                        className="form-control"
                                    />
                                </div>
                            </div>
                            {!pago_inmediato && this.renderDiasCredito()}
                            <br />
                            {this.renderDataFacturacion()}
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <div className="d-flex justify-content-center flex-column flex-sm-row align-items-stretch align-items-sm-center justify-content-sm-around ">
                                <button
                                    onClick={previousStep}
                                    className="btn btn-secondary  align-self-center"
                                >
                                    ANTERIOR
                                </button>
                                <button
                                    type="submit"
                                    className="btn btn-primary m-1 align-self-center"
                                >
                                    SIGUIENTE
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </Fragment>
        );
    }
}

PagoOCForm = reduxForm({
    form: "PeticionVentaForm", // a unique identifier for this form
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true,
    validate: (data, props) => {
        const errors = {};
        if (data.pago_inmediato == "false") {
            if (data.dias_al_credito <= 0) {
                errors.dias_al_credito = "Debe ser mayor a 0";
            }
        }
        if (data.Ordennits.length > 0) {
            let count = 0;
            const array_nits = [];
            const errores_nits = {};
            data.Ordennits.forEach((item, index) => {
                const errors_nits = {};
                if (index == 0) {
                    count = count + parseFloat(item.monto_factura);
                    if (!item.propietario) {
                        errors_nits.propietario = "Campo requerido";
                        array_nits[index] = errors_nits;
                    }
                    if (!item.nombre) {
                        errors_nits.nombre = "Campo requerido";
                        array_nits[index] = errors_nits;
                    }
                }
            });
            
            let total_facturar=0
            total_facturar=parseFloat(Number(props.total).toFixed(2))
            // total_facturar=parseFloat(total_facturar)
            if (count !== total_facturar) {
                errores_nits._error =
                    "La sumatoria no coincide con el total a facturar";
                errors.Ordennits = {
                    _error: "La sumatoria no coincide con el total a facturar",
                };
            }

            if (array_nits.length > 0) {
                // errores_nits._error =
                errors.Ordennits = { _error: "Campos invalidos" };
            }
            // validacion del nit
        } else {
            errors.Ordennits = {
                _error: "debe ingresar una forma de facturacion",
            };
        }

        if (data.Ordennits && data.Ordennits.length) {
            const array_nits = [];
            data.Ordennits.forEach((identificacion, index) => {
                const errors_nits = {};

                if (data.Ordennits[index].propietario.Nit != 'CF' || data.Ordennits[index].tipo == 'EXT') {
                    try {
                        // Verificación NIT
                        if (data.Ordennits_ref[index].propietario.tipo == "NIT" && data.Ordennits_ref[index].propietario.Nit && data.Ordennits_ref[index].propietario.nombre) {
                            if (data.Ordennits[index].propietario.label && data.Ordennits[index].propietario.label != data.Ordennits_ref[index].propietario.Nit) {
                                errors_nits.propietario = "NIT no válido.";
                                // array_nits[index] = errors_nits;
                            }
                        }
                    }
                    catch(e) {}
                    try {
                        // Verificación CUI
                        if (data.Ordennits_ref[index].propietario.tipo == "CUI" && data.Ordennits_ref[index].propietario.Nit && data.Ordennits_ref[index].propietario.nombre) {
                            if (data.Ordennits[index].propietario.label && data.Ordennits[index].propietario.label != data.Ordennits_ref[index].propietario.Nit) {
                                errors_nits.propietario = "CUI no válido.";
                                // array_nits[index] = errors_nits;
                            }
                        }
                    }
                    catch(e) {}
                }
            })
            if (array_nits.length > 0) errors.Ordennits = array_nits;
        }
        return errors
    },
})(PagoOCForm);

const selector = formValueSelector("PeticionVentaForm");
PagoOCForm = connect((state, props) => {
    let todos_productos = [];
    let simbolo = "Q. ";
    let valor_iva = 0;
    let descuento = 0;

    const productos = selector(state, "detalle_orden");
    const moneda = selector(state, "moneda");
    //const descuento = selector(state, 'descuento');
    const tiene_iva = true;
    const tipo_movimiento = selector(state, "tipo_documento");
    const proveedor = selector(state, "proveedor");
    let dias_de_credito = selector(state, "dias_al_credito");

    let pago_inmediato = selector(state, "pago_inmediato");
    let tipo_documento = "Documento de Registro";
    if (tipo_movimiento) {
        let seleccionado = _.find(TipoTransaccion, (tipo) => {
            return tipo.value == tipo_movimiento;
        });
        tipo_documento = seleccionado.ayuda;
    }
    if (moneda) {
        simbolo = getSimbolo(moneda);
    }
    if (pago_inmediato == "true") {
        pago_inmediato = true;
    } else {
        pago_inmediato = false;
    }
    if (productos) {
        todos_productos = productos;
    }

    let total = 0;
    let subtotal = 0;
    // Redondear el subtotal de cada ítem
    todos_productos.forEach(x => {
        if (!isNaN(x.cantidad) && !isNaN(x.precio_unitario)) {
            descuento += x.descuento;
        }
    });
    const subtotalesRedondeados = todos_productos.map(item => {
        const cantidad = parseFloat(item.cantidad);
        const precioUnitario = parseFloat(item.precio_unitario);
        const subtotal = cantidad * precioUnitario;
        
        return parseFloat(subtotal).toFixed(2);
    });

    // Calcular el subtotal general sumando los subtotales redondeados de cada ítem
    const subtotalGeneral = subtotalesRedondeados.reduce((total, subtotal) => total + parseFloat(subtotal), 0).toFixed(2);
    subtotal = subtotalGeneral
    total = subtotal;
    if (descuento) {
        total = total - descuento;
    }
    if (tiene_iva) {
        valor_iva = total * 0.12;
        subtotal = subtotal - valor_iva;
        // total = total + valor_iva;
    }

    let comparacion = 0;
    if (proveedor.credito_deuda.saldo <= 0) {
        comparacion =
            proveedor.limite_credito - total + proveedor.credito_deuda.saldo;
    } else {
        comparacion = proveedor.limite_credito - total;
    }

    return {
        pago_inmediato,
        simbolo,
        tipo_documento,
        total,
        proveedor: proveedor ? proveedor.nombre : "--",
        dias_credito: proveedor.dia_credito,
        Nits: proveedor.empresa,
        cliente: proveedor ? proveedor : null,
        comparacion,
    };
})(PagoOCForm);

export default PagoOCForm;
