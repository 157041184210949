import { transform } from "lodash";
import React from "react";
import { Field, reduxForm, FieldArray, formValueSelector } from "redux-form";
import { connect } from 'react-redux';
import {
    validate,
    validatorFromFunction,
    validators,
    combine,
} from "validate-redux-form";
import {
    renderField,
    renderTextArea,
    renderSelectField2,
    renderFieldDPI,
} from "../../../Utils/renderField";
import LoadMask from "../../../Utils/LoadMask/LoadMask";
const renderCuentas = ({
    fields,
    meta: { error, submitFailed },
    contactos,
    validateNit,
    setDataName,
    changeFieldClienteForm,
    getFieldClienteFormFacturacion,
    isLoader,
}) => {
    let optionsDocumentos = [
        { value: "NIT", label: "NIT", id: "NIT" },
        { value: "CUI", label: "CUI", id: "CUI" },
        { value: "EXT", label: "EXT", id: "EXT" },
    ];
    const tipoDeField = (index) => {
        let tipo = getFieldClienteFormFacturacion(index);
        switch (tipo) {
            case "NIT":
                return renderField;
            case "CUI":
                console.log("CUI");
                return renderFieldDPI;
            case "EXT":
                return renderField;
            default:
                return renderField;
        }
    };

    const readOnlyTipo = (index) => {
        let tipo = getFieldClienteFormFacturacion(index, "tipo");
        if (tipo) {//DESCOMENTAR
            // return tipo.length > 0
            //     ? ["NIT", "CUI"].includes(
            //           getFieldClienteFormFacturacion(index, "tipo")
            //       )
            //     : true;
            return tipo.length > 0
                ? ["NIT", "", "CUI"].includes(
                      getFieldClienteFormFacturacion(index, "tipo")
                  )
                : true;
        }
        return true;
    };

    const readOnlyName = (index) => {
        let tipo = getFieldClienteFormFacturacion(index, "tipo");
        if (tipo) {
            return tipo.length > 0 ? false : true;
        }
        return true;
    };

    return (
        <div className=" col-sm-12 p-0">
            <div className=" col-sm-12 form-group np-r p-0">
                <div className="react-bs-container-header table-header-wrapper">
                    <table className="table table-striped table-highlight mb-0">
                        <thead>
                            <tr>
                                <th>TIPO</th>
                                <th>IDENTIFICACIÓN</th>
                                <th></th>
                                <th>NOMBRE</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {fields.map((mNit, index) => {
                                return (
                                    <tr key={index}>
                                        <td className="sin-borde-top">
                                            <Field
                                                name={`${mNit}.tipo`}
                                                type="text"
                                                component={renderSelectField2}
                                                options={optionsDocumentos}
                                                item_vacio={true}
                                                disabled={false}
                                                readOnly={true}
                                                onChange={(e) => {
                                                    setDataName(index, {
                                                        first_name: "",
                                                        last_name: "",
                                                    });
                                                }}
                                            />
                                        </td>
                                        <td className="sin-borde-top">
                                            <Field
                                                name={`${mNit}.Nit`}
                                                type="text"
                                                component={tipoDeField(index)}
                                                onBlurs={true}
                                                onKeyDownEnter={setDataName}
                                                index={index}
                                                readOnly={readOnlyName(index)}
                                            />
                                        </td>
                                        <td
                                            className="text-center sin-borde-top boton-reactivo"
                                            style={{
                                                width: "48px",
                                            }}
                                        >
                                            <img
                                                className="action-img"
                                                src={require("assets/img/icons/buscar.png")}
                                                alt="Buscar"
                                                title="Buscar"
                                                onClick={() => {
                                                    let tipo =
                                                        getFieldClienteFormFacturacion(
                                                            index,
                                                            "tipo"
                                                        );
                                                    switch (tipo) {
                                                        case "NIT":
                                                            validateNit(index);
                                                            break;
                                                        case "CUI":
                                                            setDataName(index);
                                                            break;
                                                        case "EXT":
                                                            console.log("EXT");
                                                            break;
                                                        default:
                                                            break;
                                                    }
                                                }}
                                            />
                                        </td>
                                        <td className="sin-borde-top">
                                            <Field
                                                name={`${mNit}.nombre`}
                                                type="text"
                                                component={renderField}
                                                readOnly={readOnlyTipo(index)}
                                            />
                                        </td>
                                        <td
                                            className="text-center sin-borde-top"
                                            style={{ width: "48px" }}
                                        >
                                            <img
                                                className="action-img"
                                                src={require("assets/img/icons/Cerrar.png")}
                                                alt="Borrar"
                                                title="Borrar"
                                                onClick={() => {
                                                    fields.remove(index);
                                                }}
                                            />
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
                <div className="d-flex justify-content-start mt-4">
                    {fields.length == 0 && (
                        <button
                            type="button"
                            className="btn btn-primary py-0"
                            onClick={() => fields.push({})}
                        >
                            AGREGAR IDENTIFICACIÓN
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
};

const CuentaForm = (props) => {
    const {
        handleSubmit,
        previousStep,
        validateNit,
        es_cliente,
        setDataName,
        changeFieldClienteForm,
        getFieldClienteFormFacturacion,
        isLoader,
    } = props;
    return (
        <form
            name="FacturacionForm"
            className="form-validate mb-lg"
            onSubmit={handleSubmit}
        >
            <div className="row justify-content-center">
                <h4 className="t-primary">DOCUMENTOS DEL CLIENTE</h4>
            </div>
            <FieldArray
                name="facturacion"
                component={renderCuentas}
                validateNit={validateNit}
                es_cliente={es_cliente}
                setDataName={setDataName}
                changeFieldClienteForm={changeFieldClienteForm}
                getFieldClienteFormFacturacion={getFieldClienteFormFacturacion}
                isLoader={isLoader}
            />
            <div className="row  justify-content-sm-around justify-content-center">
                <button
                    onClick={previousStep}
                    className="btn btn-secondary m-1 align-self-center"
                >
                    ANTERIOR
                </button>
                <button
                    type="submit"
                    className="btn btn-primary m-1 align-self-center"
                >
                    SIGUIENTE
                </button>
            </div>
        </form>
    );
};

export default reduxForm({
    form: "ClientesForm", // a unique identifier for this form
    destroyOnUnmount: false, // <------ preserve form data
    forceUnregisterOnUnmount: true, // <------ unregister fields on unmount
    validate: (data, props) => {
        const errors = {};
        if (data.facturacion && data.facturacion.length) {
            const detalleArray = [];
            data.facturacion.forEach((campo, index) => {
                let detErrors = {};
                if (!campo.Nit) {
                    detErrors.Nit = "Campo requerido";
                }
                if (detErrors) {
                    detalleArray[index] = detErrors;
                }
            });
            if (detalleArray.length) {
                errors.facturacion = detalleArray;
            }
        }

        /* if (data.facturacion && data.facturacion.length) {
            const detalleArray = [];
            data.facturacion.forEach((identificacion, index) => {
                let detErrors = {};
                // Verificación NIT
                if (identificacion.tipo == "NIT" && identificacion.Nit && identificacion.nombre) {
                    let existe = _.find(props.facturacion_ref, x => x.nombre == identificacion.nombre && x.tipo=='NIT')
                    if (existe && existe.Nit != identificacion.Nit) detErrors.Nit = "NIT no válido.";
                    if (detErrors.Nit) detalleArray[index] = detErrors;
                }
                // Verificación CUI
                if (identificacion.tipo == "CUI" && identificacion.Nit && identificacion.nombre) {
                    let existe = _.find(props.facturacion_ref, x => x.nombre == identificacion.nombre && x.tipo=='CUI')
                    if (existe && existe.Nit != identificacion.Nit) detErrors.Nit = "CUI no válido.";
                    if (detErrors.Nit) detalleArray[index] = detErrors;
                }
            })
            if (detalleArray.length) errors.facturacion = detalleArray;
        } */
        return errors;
    },
})(CuentaForm);
