import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import ToolbarSimple from '../../../Utils/Toolbar/ToolbarSimple';
import Tabs, { TabPane } from 'rc-tabs';
import TabContent from 'rc-tabs/lib/TabContent';
import ScrollableInkTabBar from 'rc-tabs/lib/ScrollableInkTabBar';

import ProductosGrid from './productoGrid';
import FacturasGrid from './facturasGrid'
// import TransporteDetalle from './transporteDetalle';
// import GastoGrid from './Gastos/gastosGrid';
import PagosContainer from './Pagos/pagocContainer';
import DespachoContainer from './Despacho/despachoContainer';
import DteContainer from './Dte/DteContainer';
// import ReintegroGrid from './Reintegros/reintegro';
// import OCpreview from './previewOC/OCpreview';
import Swal from 'sweetalert2';
import { RenderMoneda, RenderDateTime } from "../../../Utils/renderField/renderTableField";
import { getSimbolo } from "../../../Utils/renderField/renderTableField";

import { TIPO_ENTREGA } from "../../../../../utility/constants";
import LoadMask from "Utils/LoadMask/LoadMask";
import DevolucionesGrid from './Devoluciones/devolucionesGrid';



export default class DetalleOC extends Component {
    static propTypes = {
        item: PropTypes.object.isRequired,
        loader: PropTypes.bool.isRequired,
        onPageChange: PropTypes.func,
        onSortChange: PropTypes.func,
    };

    static defaultProps = {
        loading: false
    };

    componentDidMount() {
        this.props.clear()
        let id = this.props.match.params.id
        this.props.leerOrdenCompra(id);


    }
    getEntrega = (valor) => {
        let entrega = ''
        TIPO_ENTREGA.forEach((item, index) => {
            if (item.value === valor) {
                entrega = item.label
            }
        })
        return entrega
    }
   finalizarOrden = () => {
        Swal.fire({
            title: '¿Desea finalizar la orden de venta?',
            text: '¡No podrá revertir esta acción!',
            type: 'question',
            showCancelButton: true,
            confirmButtonText: '¡Sí, finalizar!',
            cancelButtonText: 'No, cancelar',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                this.props.finalizarOrden(this.props.item.orden.id);
            }
        });
    }
    botonDevolucionPDF = (cell, row) => {
        return (
            <div className="">
                {/* <span className="font-weight-bold">PDF:</span> */}
                <a className="px-2 t-musgo" style={{cursor: "pointer"}}
                    target="_blank"
                    href={row.recibos[0].factura}>
                    <img
                        className="action-img"
                        title="PDF Devolucion"
                        src={require("../../../../../../assets/img/icons/Cuentas1.png")} alt="PDF"/>
                </a>
            </div>
        )
    }

    render() {
        const { item, data, loader, listar: onPageChange, onSortChange, eliminar, cuentas, rol } = this.props;
        let CPid = this.props.match.params.id
        let TIPO_FACTURA = 10;
        let dte_facturas = [];
        if (item.orden && item.orden.facturas) dte_facturas = _.filter(item.orden.facturas, { tipo_dte: TIPO_FACTURA });

        return (
            <div className="Container bg-white">
                <LoadMask loading={loader} light type={"TailSpin"}>

                    {item && item.orden && item.orden.proceso_estado === 100 && (
                        <div className="m-t p-t fnt-size">
                            <div className="row m-none" style={{ paddingTop: "15px" }}>
                                <div className="col-lg-6 col-md-6 col-12 d-flex align-items-center">
                                    <h3 className="t-primary m-none">{item.orden ? item.orden.no_orden : ""}</h3>
                                </div>
                            </div>
                            <div className="row m-none" style={{ paddingTop: "15px" }}>
                                {/* <div className="row col-md-12  d-flex justify-content-between"> */}
                                <div className="col-lg-3 col-sm-6 col-12 d-flex align-items-end">
                                    <span className="m-none t-mostaza semibold">subtotal:</span>
                                    <span className="m-none t-musgo semibold"> <RenderMoneda monto={item.orden.subtotal} simbolo={getSimbolo(this.props.moneda)} /></span>
                                </div>
                                <div className="col-lg-3 col-sm-6 col-12 d-flex align-items-end">
                                    <span className="m-none t-mostaza semibold">Descuento:</span>
                                    <span className="m-none t-musgo semibold"> <RenderMoneda monto={item.orden.descuento} simbolo={getSimbolo(this.props.moneda)} /></span>
                                </div>
                                <div className="col-lg-3 col-sm-6 col-12 d-flex align-items-end">
                                    <span className="m-none t-mostaza semibold">IVA: </span>
                                    <span className="m-none t-musgo semibold"><RenderMoneda monto={item.orden.iva} simbolo={getSimbolo(this.props.moneda)} /></span>
                                </div>
                                <div className="col-lg-3 col-sm-6 col-12 d-flex align-items-end">
                                    <span className="m-none t-mostaza semibold">Total: </span>
                                    <span className="m-none t-musgo semibold"><RenderMoneda monto={item.orden.monto} simbolo={getSimbolo(this.props.moneda)} /> </span>
                                </div>
                                {/* </div> */}
                            </div>
                            <br />
                            {/* DATOS GENERALES */}
                            <hr style={{ borderTop: "2px solid rgb(223, 224, 224)", margin: "10px 15px" }} />
                            <div className="row m-none">
                                <div className="col-lg-4 col-md-6 col-12">
                                    <span className="m-none t-mostaza semibold">Emitida por: </span><br />
                                    <span className="m-none t-musgo semibold">{`${item.orden.emitido_por.first_name} ${item.orden.emitido_por.last_name}`}</span>
                                </div>
                                <div className="col-lg-4 col-md-6 col-12 ">
                                    <span className="m-none t-mostaza semibold">Autorizada por: </span><br />
                                    <span className="m-none t-musgo semibold">{`${item.orden.aprobado_por.first_name} ${item.orden.aprobado_por.last_name}`}</span>
                                </div>
                                <div className="col-lg-4 col-md-6 col-12">
                                    <span className="m-none t-mostaza semibold">Dirigida a: </span><br />
                                    <span className="m-none t-musgo semibold">{`${item.orden.proveedor.nombre}`}</span>
                                </div>
                            </div>
                            <br />{/* DESCRIPCION */}
                            <div className="col-12">
                                <span className="m-none t-primary bold" >Descripción</span>
                            </div>
                            <hr style={{ borderTop: "2px solid rgb(223, 224, 224)", margin: "0px 15px 10px 15px" }} />
                            <div className="row m-none">
                                <div className="col-12">
                                    <span className="m-none t-musgo semibold">{`${item.orden.descripcion}`}</span>
                                </div>
                            </div>
                            <br /> {/*CONDICIONES DE ENTREGA */}
                            <div className="col-12">
                                <span className="m-none t-primary bold" >Fecha y lugar de entrega </span>
                            </div>
                            <hr style={{ borderTop: "2px solid rgb(223, 224, 224)", margin: "0px 15px 10px 15px" }} />
                            <div className="row m-none">
                                <div className="col-md-4 col-sm-6 col-12">
                                    <span className="m-none t-mostaza semibold">Fecha aproximada de entrega: </span><br />
                                    <span className="m-none t-musgo semibold"><RenderDateTime fecha={item.orden.fecha_prox_entrega} ></RenderDateTime></span>
                                </div>
                                <div className="col-md-4 col-sm-6 col-12">
                                    <span className="m-none t-mostaza semibold">Condicion de Entrega: </span><br />
                                    <span className="m-none t-musgo semibold">{`${this.getEntrega(item.orden.tipo_entrega)}`}</span>
                                </div>
                                <div className="col-md-4 col-sm-6 col-12">
                                    {
                                        item.orden.nombre_transporte && (
                                            <div>
                                                <span className="m-none t-mostaza semibold">Tramsporte: </span><br />
                                                <span className="m-none t-musgo semibold">{`${item.orden.nombre_transporte}`}</span>
                                            </div>
                                        )
                                    }
                                </div>
                                <div className="col-md-4 col-sm-6 col-12">
                                    <span className="m-none t-mostaza semibold">Direccion: </span><br />
                                    <span className="m-none t-musgo semibold">{`${item.orden.direccion}`}</span>
                                </div>
                                <div className="col-md-4 col-sm-6 col-12">
                                    <span className="m-none t-mostaza semibold">Encargado: </span><br />
                                    <span className="m-none t-musgo semibold">{`${item.orden.encargado}`}</span>
                                </div>
                            </div>
                            <br /> {/*CONDICIONES COMERCIALES */}
                            <div className="col-12">
                                <span className="m-none t-primary bold" >Condiciones Comerciales</span>
                            </div>
                            <hr style={{ borderTop: "2px solid rgb(223, 224, 224)", margin: "0px 15px 10px 15px" }} />
                            <div className="row m-none">
                                <div className="col-sm-4 col-12">
                                    <span className="m-none t-mostaza semibold">Tipo de Pago: </span><br />
                                    <span className="m-none t-musgo semibold">{`${item.orden.pago_inmediato ? "Pago Inmediado" : "Al Credito"}`}</span>
                                </div>
                                <div className="col-sm-4 col-12">
                                    <span className="m-none t-mostaza semibold">Moneda: </span><br />
                                    <span className="m-none t-musgo semibold">{`${item.orden.moneda}`}</span>
                                </div>
                                {
                                    (item.orden.dias_al_credito > 0) && (
                                        <div className="col-sm-4 col-12">
                                            <span className="m-none t-mostaza semibold">Días de crédito: </span><br />
                                            <span className="m-none t-musgo semibold">{`${item.orden.dias_al_credito}`}</span>
                                        </div>
                                    )
                                }
                            </div>
                            <br />
                            {
                                (item.orden) && (item.orden.Ordennits) && (!!item.orden.Ordennits.length) && (
                                    <div className="row m-none">
                                        <div className=" col-12 justify-content-center">
                                            <span className="m-none t-mostaza semibold">Facturas Solicitadas</span><br />
                                            {item.orden.Ordennits.map((nit, index) => {
                                                return (
                                                    <div key={index}>
                                                        <div className="row">
                                                            <div className="col-sm-4 col-12">
                                                                <span className="m-none t-mostaza semibold">{`${nit ? nit.tipo : "--"}`}:</span><br />
                                                                <span className="m-none t-musgo semibold">{`${nit.propietario ? nit.propietario.Nit : "--"}`}</span>
                                                            </div>
                                                            <div className="col-sm-4 col-12">
                                                                <span className="m-none t-mostaza semibold">Nombre: </span><br />
                                                                <span className="m-none t-musgo semibold">{`${nit.nombre ? nit.nombre : "--"}`}</span>
                                                            </div>
                                                            <div className="col-sm-4 col-12">
                                                                <span className="m-none t-mostaza semibold">Monto: </span><br />
                                                                <span className="m-none t-musgo semibold">{nit.monto_factura ? <RenderMoneda monto={nit.monto_factura} simbolo={getSimbolo(this.props.moneda)} /> : "--"}</span>
                                                            </div>
                                                        </div>
                                                        <hr style={{ borderTop: "2px solid rgb(223, 224, 224)", margin: "0px" }} />
                                                    </div>
                                                )
                                            })}
                                        </div>
                                    </div>
                                )
                            }
                            <br /> {/* DESCRIPCION DE ANULACION */}
                            {
                                (!item.estado) && (
                                    <div>
                                        <div className="col-12">
                                            <span className="m-none t-primary bold" >Descripción de Anulacion</span>
                                        </div>
                                        <hr style={{ borderTop: "1px solid #00334e", margin: "0px 15px 10px 15px" }} />
                                        <div className="row m-none">
                                            <div className="col-12">
                                                <span className="m-none t-musgo semibold">{`${item.descripcion_anulacion}`}</span>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                            <div className="row m-none">
                                <div className="col-6">
                                {
                                    item.orden.documento && item.orden.estado && (
                                            <a target="_blank" href={item.orden.documento_sin_detalle} className="btn btn-azul-claro align-self-center">VER DOCUMENTO</a>
                                    )
                                }
                                {
                                    item.orden.documento && item.orden.estado && (
                                            <a target="_blank" href={item.orden.documento} className="btn btn-azul-claro align-self-center ml-1">VER DOCUMENTO CON DETALLES</a>
                                    )
                                }
                                  { item.orden.entregado==false && !this.props.permisos.visualizar_mis_datos &&(
                                    <button type="button" onClick={(e) => {
                                       e.preventDefault();
                                       this.finalizarOrden();
                                       }} className="btn  btn-mostaza m-1 align-self-center"
                                        >FINALIZAR ORDEN DE VENTA</button>
                                  )}
                                </div>
                                {
                                    !item.orden.a_produccion && (
                                        <div className="col-6">
                                            <button className="btn btn-primary align-self-center"
                                                onClick={e => {
                                                    this.props.AutorizarProduccion(item.orden.id);
                                                }
                                                }
                                            >
                                                AUTORIZAR PRODUCCION
                                            </button>
                                        </div>
                                    )
                                }
                            </div>
                            <br /> {/*  */}
                            <div className="row">
                                <div className="mb-4 col-12">
                                    <div className="mb-4 ">
                                        <div className=""><h6 className="m-0"></h6></div>
                                        <div className="p-0 px-3 pt-3 p-b">
                                            <Tabs
                                                defaultActiveKey="DETALLEOV"
                                                tabBarPoition="top"
                                                onchange={this.callback}
                                                renderTabBar={() => <ScrollableInkTabBar />}
                                                renderTabContent={() => <TabContent />}

                                            >
                                                <TabPane tab="PRODUCTOS" key="PRODUCTOS">
                                                    <ProductosGrid
                                                        detalle_orden={item.orden.detalle_orden}
                                                        moneda={item.moneda}
                                                    />
                                                </TabPane>
                                                {/* <TabPane tab="COBROS " key="COBROS">
                                                <PagosContainer
                                                    id_cuenta={this.props.match.params.id}
                                                    id_empresa={item.empresa}
                                                    selectCuentas={this.props.selectCuentas}
                                                    {...this.props}
                                                    cuentas={cuentas}
                                                    orden={item}
                                                    pagos_credito={this.props.pagos_credito}
                                                    moneda={item.moneda}
                                                />
                                            </TabPane> */}
                                                <TabPane tab="FACTURAS" key="FACTURAS">
                                                    <FacturasGrid
                                                        facturas={dte_facturas}
                                                        moneda={item.moneda}
                                                        OCid={CPid}
                                                        BillOC={this.props.BillOC}
                                                        DeleteOC={this.props.DeleteOC}
                                                        orden={item.orden}
                                                    />
                                                </TabPane>
                                                {
                                                    <TabPane tab="DTE" key="DTE">
                                                        <DteContainer
                                                            dte_list={item.orden.dtes}
                                                            id={this.props.match.params.id}
                                                            orden={item.orden}
                                                        />
                                                    </TabPane>
                                                }
                                                { item.orden.a_produccion &&
                                                    (item.orden) && (item.orden.detalle_orden) && (item.orden.detalle_orden.length) && (
                                                        <TabPane tab="DESPACHO" key="DESPACHO">
                                                            <DespachoContainer
                                                                data={this.props.history}
                                                                historial={this.props.historial}
                                                                HistoryDispatch={this.props.HistoryDispatch}
                                                                historialEntregas={this.props.historialEntregas}
                                                                AutorizarDespacho={this.props.AutorizarDespacho}
                                                                orden={item.orden}
                                                                permisos={this.props.permisos}
                                                                rol={rol}
                                                            />
                                                        </TabPane>
                                                    )
                                                }
                                                {
                                                item.devoluciones &&
                                                    item.devoluciones.length > 0 &&
                                                        <TabPane tab="DEVOLUCIONES" key="DEVOLUCIONES">
                                                            <DevolucionesGrid
                                                                devoluciones={item.devoluciones}
                                                                detalle_orden={item.orden.detalle_orden}
                                                                botonDevolucionPDF={this.botonDevolucionPDF}
                                                            />
                                                        </TabPane>

                                                }
                                            </Tabs>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                    }
                    {
                        (item && item.proceso_estado === 60) && (
                            <OCpreview
                                item={item}
                                loader={loader}
                                id={this.props.match.params.id}
                                refusePM={this.props.refusePM}
                                acceptPM={this.props.acceptPM}
                                clear={this.props.clear}
                            />
                        )

                    }
                </LoadMask>
            </div>

        )
    }
}

